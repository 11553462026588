<template>
  <div>
    <div class="banner">
      
      <main-top title="同款\相似分析" desc="本工具可以在线查询天猫、淘宝商品宝贝问大家。" />

      <!-- 搜索 -->
      <bordure-row title="宝贝同款/分析" style="margin-bottom:30px;" shadow>
        <div class="multipleSearch">
          <div style="margin: 0 0 30px 0;text-align: center;">
            <el-radio-group v-model="mode">
              <el-radio-button label="0" class="same_radio_diy">找相似</el-radio-button>
              <el-radio-button label="1" class="same_radio_diy">找同款</el-radio-button>
              <!-- <el-radio label="0" border>找相似</el-radio>
              <el-radio label="1" border>找同款</el-radio> -->
            </el-radio-group>
          </div>
          <el-form :inline="true" :model="formInline" class="demo-form-inline" :rules="rules" ref="formInline">
            <el-form-item prop="goodsId">
              <el-input v-model="formInline.goodsId" style="width: 723px;" placeholder="请输入需要查询的宝贝链接/id">
                <i class="el-icon-search el-input__icon" slot="prefix" style="font-size:26px;line-height:50px;padding:0 10px;"></i>
              </el-input>
            </el-form-item>
            <el-button type="primary" @click="onSubmit('formInline')" style="width:145px;height:50px;">查询</el-button>
          </el-form>
        </div>
      </bordure-row>

      <!-- table表格 -->
      <bordure-row title="商品信息" style="margin-bottom:30px;">

        <!-- 单选框 -->
        <!-- <div style="height: 40px">
            <span>需要查询的排序方式：</span>
            <el-radio-group v-model="radio" @change="changeRadio">
              <el-radio
                v-for="(item, index) in sort"
                :key="index"
                :label="item.radio"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
        </div>-->
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="item_id" label="商品ID" align="center" header-align="center" width="150">
          </el-table-column>
          <el-table-column prop="title" label="商品名称" align="center" header-align="center"></el-table-column>
          <el-table-column prop="shop" label="商品店铺名" align="center" header-align="center" width="200"></el-table-column>
          <el-table-column label="商品图片" align="center" header-align="center" width="150">
            <template slot-scope="scope">
              <img style="width: 100px; height: 100px" :src="scope.row.pic_path" alt />
            </template>
          </el-table-column>
          <el-table-column prop="price" label="价格" align="center" width="100" sortable header-align="center">
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <!-- <el-pagination
            style="float: right; margin: 20px 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="totalPage"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
          >
        </el-pagination>-->
      </bordure-row>
      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>
<script>
  
  import {
    queryExoGoodsList
  } from "../../request/api"; //这里是引入请求
  import Introduce from "../public/Introduce";

  export default {
    data() {
      return {
        CanYouClick: true, //是否允许再次搜索
        // 基本信息data
        formInline: {
          goodsId: "",
          searchKey: "",
        },

        //表单的校验规则
        rules: {
          goodsId: [{
              required: true,
              message: "请输入宝贝ID或宝贝链接",
              trigger: "blur",
            },
            {
              min: 5,
              message: "请输入正确的宝贝ID或宝贝链接",
              trigger: "blur"
            },
          ],
        },
        tableData: [{
            sold: "7",
            shop: "李红国际旗舰店",
            priceWap: 589,
            item_id: "609427481266",
            price: 589,
            pic_path: "//img.alicdn.com/bao/uploaded/i4/1970902626/O1CN01d4zsa51VGke7gfi2a_!!0-item_pic.jpg",
            title: "LEIHON/李红国际秋冬通勤POLO领拉链门襟印花H版中老妈妈短外套",
            userId: "1970902626",
          },
          {
            sold: "0",
            shop: "李红国际旗舰店",
            priceWap: 898,
            item_id: "633002387934",
            price: 898,
            pic_path: "//img.alicdn.com/bao/uploaded/i4/1970902626/O1CN01QCTfFf1VGki1YFOSI_!!0-item_pic.jpg",
            title: "LEIHON/李红国际2020年秋冬通勤拉链门襟碎花棉衣休闲H版短外套女",
            userId: "1970902626",
          },
          {
            sold: "6",
            shop: "李红国际旗舰店",
            priceWap: 589,
            item_id: "609083140997",
            price: 589,
            pic_path: "//img.alicdn.com/bao/uploaded/i3/1970902626/O1CN01bk7I2u1VGke5Jgd1J_!!0-item_pic.jpg",
            title: "LEIHON/李红国际2021春秋季通勤拉链门襟中老年印花棉衣H版短外套",
            userId: "1970902626",
          },
          {
            sold: "7",
            shop: "邢氏旗舰店",
            priceWap: 787,
            item_id: "607104806728",
            price: 787,
            pic_path: "//img.alicdn.com/bao/uploaded/i1/381690975/O1CN01AYWJ151J4ajWBfZpw_!!381690975.jpg",
            title: "邢氏女装 大码连帽短款棉服女秋冬新款宽松显瘦时尚妈妈小棉袄",
            userId: "381690975",
          },
          {
            sold: "5",
            shop: "唐朱迪旗舰店",
            priceWap: 499,
            item_id: "626683769801",
            price: 499,
            pic_path: "//img.alicdn.com/bao/uploaded/i1/2121001985/O1CN01rHjaHP1QXAoOeOlyf_!!0-item_pic.jpg",
            title: "唐朱迪商场同款棉袄2020新款冬装女减龄纯色刺绣短款棉服T114806",
            userId: "2121001985",
          },
          {
            sold: "8",
            shop: "易菲服饰旗舰店",
            priceWap: 429,
            item_id: "624216379942",
            price: 429,
            pic_path: "//img.alicdn.com/bao/uploaded/i3/676155566/O1CN01ID1M0a1qzHAqsFg8x-676155566.jpg",
            title: "Yifini/易菲秋冬新间线修身显瘦短款蓝色金典棉衣外套女2010L962",
            userId: "676155566",
          },
          {
            sold: "65",
            shop: "易菲服饰旗舰店",
            priceWap: 289,
            item_id: "608732741986",
            price: 289,
            pic_path: "//img.alicdn.com/bao/uploaded/i2/676155566/O1CN01mKzwsu1qzHAjv1VWH-676155566.jpg",
            title: "Yifini/易菲棉袄棉衣爆款棉服女韩版宽松连帽短款外套潮冬季新款",
            userId: "676155566",
          },
          {
            sold: "4",
            shop: "易菲服饰旗舰店",
            priceWap: 429,
            item_id: "629780048500",
            price: 429,
            pic_path: "//img.alicdn.com/bao/uploaded/i3/676155566/O1CN019Jf0LM1qzHAbOfoJt-676155566.jpg",
            title: "Yifini/易菲20冬新款红色连帽宽松短款棉服女棉衣棉袄夹棉外套",
            userId: "676155566",
          },
          {
            sold: "11",
            shop: "颜域旗舰店",
            priceWap: 539,
            item_id: "605248562630",
            price: 539,
            pic_path: "//img.alicdn.com/bao/uploaded/i1/1020209367/O1CN01ey6e1a2J48Vuuv49w_!!0-item_pic.jpg",
            title: "颜域女装时尚连帽短款棉服女冬装2020年新款通勤百搭保暖棉衣外套",
            userId: "1020209367",
          },
          {
            sold: "6",
            shop: "珂影依旗舰店",
            priceWap: 728,
            item_id: "630208324425",
            price: 728,
            pic_path: "//img.alicdn.com/bao/uploaded/i4/3486387964/O1CN013XNNpm28hYtvuJdjC_!!0-item_pic.jpg",
            title: "宽松真丝棉衣外套女冬季新款气质毛领长袖复古中老年棉服女中长款",
            userId: "3486387964",
          },
          {
            sold: "8",
            shop: "李红国际旗舰店",
            priceWap: 449,
            item_id: "609811303765",
            price: 449,
            pic_path: "//img.alicdn.com/bao/uploaded/i1/1970902626/O1CN01qrfSLq1VGke1Ps4qA_!!0-item_pic.jpg",
            title: "李红国际2021年春秋季连帽印花拉链大码中老年妈妈装中长款短外套",
            userId: "1970902626",
          },
        ],
        totalPage: 1, //初始页
        pageSize: 20, //    每页的数据
        totalCount: 10, //表格总数
        // radio: "1", //单选框
        mode: "1", //单选框
        sort: [{
            radio: "1",
            name: "综合排序",
          },
          {
            radio: "2",
            name: "新品排序",
          },
          {
            radio: "3",
            name: "销量由高到低",
          },
          {
            radio: "4",
            name: "销量由低到高",
          },
          {
            radio: "5",
            name: "价格由高到低",
          },
          {
            radio: "6",
            name: "价格由低到高",
          },
          {
            radio: "7",
            name: "信用由高到低",
          },
          {
            radio: "8",
            name: "信用由低到高",
          },
        ],
        introduce: [{
          title: "宝贝查询同款/分析有什么作用？",
          icon: "&#xe60a;",
          content: [
            "可以找到同款和相似款快速打标签",
          ],
        }, ], //介绍相关数组
      };
    },
    methods: {
      //   改变单选框时触发
      changeRadio(value) {
        this.onSubmit("formInline");
      },
      //点击查询
      onSubmit(formName) {
        //表单验证的方法
        this.$refs[formName].validate((valid) => {
          //判断是否有填正确的id
          if (valid) {
            let inputGoodsId = new String(this.formInline.goodsId) || "";
            inputGoodsId = inputGoodsId.trim();
            let goodsId = '';
            if (!isNaN(inputGoodsId)) {
                goodsId = inputGoodsId;
            } else {
              goodsId = this.$comm.getUrlParam(inputGoodsId, 'id')
            }
            
            this.$store.commit("alterJumpFlag", true);
            this.$store.commit("alterLoadContent", "正在搜索宝贝同款/相似分析");
            this.tableData = []
            this.expands = [];
            queryExoGoodsList({
              itemid: goodsId, //宝贝id  609427481266
              mode: this.mode, //搜索模式， 0 - 搜索相似商品 1 - 搜索同款商品
              page_num: this.totalPage, // 页码  "1"
              page_size: this.pageSize, // 每页数据条数，最大值 "20"
              sort: 1, //排序方式，1-综合排序,2-新品排序,3-销量由高到低,4-销量由低到高,5-价格由高到低,6-价格由低到高,7-信用由高到低,8-信用由低到高
              keyword: 0, //关键词  "0"
            }).then((data) => {
              //发送请求
              this.tableData = data.data.exoGoods.ItemsList;
            }).finally(() => {
              this.$store.commit("alterJumpFlag", false); //关掉遮罩层
            });
          } else {
            console.log("error 失败了");
            return false;
          }
        });
      },
      // size-change和current-change事件来处理页码大小和当前页变动时候触发的事件。
      // handleSizeChange: function (size) {
      //   this.pageSize = size; //每页下拉显示数据
      //   this.onSubmit("formInline");
      // },
      // handleCurrentChange: function (currentPage) {
      //   this.totalPage = currentPage; //点击第几页
      //   this.onSubmit("formInline");
      // },
    },
    components: {
      Introduce,
    },
  };
</script>

<style lang="less" scoped>
.demo-form-inline{
  text-align: center;
}
  .multipleSearch {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 16px 31px;
    box-sizing: border-box;
    margin-bottom: 20px;

    /deep/.el-form-item__label,
    /deep/.el-input--small,
    /deep/.el-input__inner {
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
    }

    /deep/.el-form-item__error {
      font-size: 14px;
      color: #f70000;
      letter-spacing: 0;
    }
  }


  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #333333;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #5d7cff;
    background: #5d7cff;
  }

  .multipleSearchBottom {
    padding: 0 80px 100px;
    text-align: center;
  }

  .same_radio_diy {
    /deep/.el-radio-button__inner {
      width: 140px;
      height: 44px;
      line-height: 18px;
    }
  }

  /deep/.el-input--prefix {
    /deep/.el-input__inner{
          padding-left: 50px;
    }
  }
</style>